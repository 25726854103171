import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { Row, Col } from "react-bootstrap"

// markup
const NotFoundPage = () => {
    return (
        <Layout pageTitle="404">
            <Row className="mt-5">
                <Col>
                    <p className="lead">
                        The page you are looking for could not be found. More
                        content is being added all the time so come back later
                        to find what you are looking for.
                    </p>
                    <Link to="/">Go home</Link>
                </Col>
            </Row>
        </Layout>
    )
}

export default NotFoundPage
